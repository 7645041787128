import React, { useState, useEffect } from 'react';
import useStyles from './Language.style';
import Slider from 'react-slick';
import horizontalLine from '../../assets/img/icons/line_horizontal.png';
import featuresArt from '../../assets/img/temp_art_features_920x370.png';
import nextArrow from '../../assets/img/icons/arrow3.png';
import prevArrow from '../../assets/img/icons/arrow1.png';

function Language(props) {
  const classes = useStyles();

  const [active, setActive] = useState('show');

  useEffect(() => {}, []);

  // const handleClose = () => {
  //     setActive('hiding');
  //     setActive('hide');
  // }

  const settings = {
    dots: true,
    infinite: true,
    nextArrow: <img src={nextArrow} />,
    prevArrow: <img src={prevArrow} />,
  };

  return (
    <section
      className={classes.root + ' ' + active + '  language-section mobile-blur'}
      style={{
        paddingBottom: document.getElementById('main-header').offsetHeight,
      }}
    >
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col col-xl-12'}>
            <h1 className={classes.header}>
              Language
              <span className={'close-button'} onClick={props.handleClick} />
            </h1>
            <img className={classes.horizontalLine} src={horizontalLine} />
          </div>
        </div>
        <div className={classes.langSwitcher + ' ' + 'row'}>
          <div className={'col col-12 col-md-3'}></div>
          <div className={'col col-6 col-md-3'}>
            <a className={classes.langIcon} href="/pl">
              <span className={'icon' + ' ' + 'polish'}></span>
            </a>
          </div>
          <div className={'col col-6 col-md-3'}>
            <a className={classes.langIcon} href="/en">
              <span className={'icon' + ' ' + 'english'}></span>
            </a>
          </div>
          <div className={'col col-12 col-md-3'}></div>
        </div>
      </div>
    </section>
  );
}

export default Language;
