import React from 'react';
import { createUseStyles } from 'react-jss';
import arrowLeft from '../../assets/img/icons/arrow1.png';
import arrowLeftSel from '../../assets/img/icons/arrow1_selected.png';
import arrowRight from '../../assets/img/icons/arrow3.png';
import arrowRightSel from '../../assets/img/icons/arrow3_selected.png';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    // 'radial-gradient(50% 8647.03% at 50% 50%, rgba(0, 0, 0, 0.5) 37.97%, rgba(0, 0, 0, 0) 100%)',
    backdropFilter: 'blur(15px)',
    zIndex: 1000,

    '& .slick-arrow': {
      width: '6rem',
      height: '6rem',
      '&::before': {
        content: 'unset',
      },
      '&::after': {
        content: "'' !important",
        display: 'block',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        zIndex: 1000,
        opacity: 1,
      },
      '&.slick-prev': {
        left: '-6rem',
        '&::after': {
          backgroundImage: `url(${arrowLeft})`,
        },
        '&:hover::after': {
          backgroundImage: `url(${arrowLeftSel})`,
        },
      },
      '&.slick-next': {
        right: '-6rem',
        '&::after': {
          backgroundImage: `url(${arrowRight})`,
        },
        '&:hover::after': {
          backgroundImage: `url(${arrowRightSel})`,
        },
      },
    },
    '&.hide': {
      display: 'none',
    },
    '& .container': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      width: '80%',
      maxWidth: '960px',
    },
    '& h1': {
      fontSize: '3rem',
      fontWeight: '700',
      textAlign: 'center',
      textTransform: 'uppercase',
      color: '#CC985E',
    },
  },
  header: {},
  horizontalLine: {
    display: 'block',
    width: '100%',
    objectFit: 'contain',
    margin: '0 auto',
  },
  slider: {
    '& .slide-arrow': {},
    '& img.slick-arrow': {
      height: 'auto',
      width: '6rem',
      '&.slick-prev': {
        left: '-6rem',
      },
      '&.slick-next': {
        right: '-6rem',
      },
    },
    '& .single-slide': {
      '& .image-wrapper': {
        '& img': {
          width: '100%',
        },
      },
      '& .content-wrapper': {
        '& h2': {
          color: '#CC985E',
          fontSize: '2rem',
          fontWeight: '700',
          textAlign: 'center',
          textTransform: 'uppercase',
        },
        '& p': {
          color: '#CC985E',
          textAlign: 'center',
        },
      },
    },
  },
});

export default useStyles;
