async function getAcf(param) {
  const response = await fetch(
    process.env.REACT_APP_WP_ADMIN_URL +
      '/wp-json/wp/v2/pages?slug=' +
      param +
      '&&_fields[]=acf&&acf_format=standard'
  );
  console.log(
    process.env.REACT_APP_WP_ADMIN_URL +
      '/wp-json/wp/v2/pages?slug=' +
      param +
      '&&_fields[]=acf&&acf_format=standard'
  );
  return await response.json();
}

export default getAcf;
