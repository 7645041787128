import React from 'react';
import { createUseStyles } from 'react-jss';

import polishIcon from '../../assets/img/icons/menu_button_lang_polish_132x132.png';
import polishIconSel from '../../assets/img/icons/menu_button_lang_polish_sel_132x132.png';

import englishIcon from '../../assets/img/icons/menu_button_lang_english_132x132.png';
import englishIconSel from '../../assets/img/icons/menu_button_lang_english_sel_132x132.png';

import arrowLeft from '../../assets/img/icons/arrow1.png';
import arrowRight from '../../assets/img/icons/arrow3.png';
import arrowLeftSel from '../../assets/img/icons/arrow1_selected.png';
import arrowRightSel from '../../assets/img/icons/arrow3_selected.png';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background:
      'radial-gradient(50% 8647.03% at 50% 50%, rgba(0, 0, 0, 0.5) 37.97%, rgba(0, 0, 0, 0) 100%)',
    backdropFilter: 'blur(15px)',
    zIndex: 1000,

    '& .slick-arrow': {
      width: '6rem',
      height: '6rem',
      '&::before': {
        content: 'unset',
      },
      '&::after': {
        content: "'' !important",
        display: 'block',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        zIndex: 1000,
        opacity: 1,
      },
      '&.slick-prev': {
        left: '-6rem',
        '&::after': {
          backgroundImage: `url(${arrowLeft})`,
        },
        '&:hover::after': {
          backgroundImage: `url(${arrowLeftSel})`,
        },
      },
      '&.slick-next': {
        right: '-6rem',
        '&::after': {
          backgroundImage: `url(${arrowRight})`,
        },
        '&:hover::after': {
          backgroundImage: `url(${arrowRightSel})`,
        },
      },
    },
    '&.hide': {
      display: 'none',
    },
    '& .container': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '80%',
      maxHeight: '680px',
      width: '80%',
      maxWidth: '960px',
    },
    '& h1': {
      fontSize: '3rem',
      fontWeight: '700',
      textAlign: 'center',
      textTransform: 'uppercase',
      color: '#CC985E',
    },
  },
  header: {},
  paragraph: {
    color: '#CC985E',
    textAlign: 'center',
    paddingBottom: '0.5rem',
    '& a': {
      textDecoration: 'underline',
      color: 'inherit',
    },
  },
  horizontalLine: {
    display: 'block',
    width: '100%',
    margin: '0 auto',
    paddingBottom: '0.5rem',
    objectFit: 'contain',
  },
  slider: {
    '& img.slick-arrow': {
      height: 'auto',
      width: '6rem',
    },
    '& .single-slide': {
      '& .image-wrapper': {
        '& img': {
          width: '100%',
        },
      },
      '& .header-wrapper': {
        '& h4': {
          color: '#CC985E',
          fontSize: '1.5rem',
          fontWeight: '700',
          textAlign: 'left',
          textTransform: 'uppercase',
          paddingBottom: '0.5rem',
        },
        '& p': {
          color: '#CC985E',
          textAlign: 'left',
          paddingBottom: '0.5rem',
        },
      },
      '& .content-wrapper': {
        '& p': {
          color: '#CC985E',
          textAlign: 'justify',
        },
      },
    },
  },
  langSwitcher: {
    '& .col': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
    },
  },
  langIcon: {
    display: 'block',
    height: 'auto',
    width: 'auto',
    margin: 'auto',
    '& .icon': {
      display: 'block',
      height: '132px',
      width: '132px',
      margin: 'auto 0',
      backgroundSize: 'auto',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '1rem',
      '&.polish': {
        backgroundImage: `url(${polishIcon})`,
        '&:hover': {
          backgroundImage: `url(${polishIconSel})`,
        },
      },
      '&.english': {
        backgroundImage: `url(${englishIcon})`,
        '&:hover': {
          backgroundImage: `url(${englishIconSel})`,
        },
      },
    },
  },
  // nextArrow: {
  //     backgroundColor: "red",
  //
  //     "&:after": {
  //         content: "",
  //         display: "block"
  //     }
  // },
  // prevArrow: {
  //     backgroundColor: "red",
  //     "&:before": {
  //         display: "none",
  //     },
  //     "&:after": {
  //         content: "",
  //         display: "block"
  //     }
  // }
});

export default useStyles;
