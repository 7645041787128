import React, { useState, useEffect, useRef } from 'react';
import bgLayer01 from '../../assets/img/layers/layer_01_v2.png';
import bgLayer02 from '../../assets/img/layers/layer_02_v3.png';
import bgLayer03 from '../../assets/img/layers/layer_03_fog_v4.png';
import bgLayer04 from '../../assets/img/layers/layer_04_v2.png';
import bgLayer05 from '../../assets/img/layers/layer_05_v2.png';
import bgLayer06 from '../../assets/img/layers/layer_06_fog_v3.png';
import bgLayer07 from '../../assets/img/layers/layer_07_v2.png';
import bgLayer08 from '../../assets/img/layers/layer_08_v2.png';
import beast01 from '../../assets/img/beasts/beast_01_v3.png';
import beast02 from '../../assets/img/beasts/beast_02_v2.png';
import beast03 from '../../assets/img/beasts/beast_03_v2.png';
import mobileBg from '../../assets/img/mobile-bg.mp4';

import babo from '../../assets/img/beasts/webp/babo.webp';
import cruelick from '../../assets/img/beasts/webp/crue.webp';
import green from '../../assets/img/beasts/webp/green.webp';
import pear from '../../assets/img/beasts/webp/pear.webp';
import shi from '../../assets/img/beasts/webp/shi.webp';
import wolf from '../../assets/img/beasts/webp/wolf.webp';

import useStyles from './Main.style';
import Menu from '../Menu/Menu';
import Parallax from 'parallax-js';

function Main(props) {
  const classes = useStyles();

  const sceneEl = useRef(null);

  const [beast, setBeast] = useState(Math.floor(Math.random() * 6) + 1);
  const [mobileResolution, setMobileResolution] = useState(true);
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (!mobileResolution) {
      console.log(beast);

      const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
        hoverOnly: true,
        limitX: 100,
        limitY: 100,
      });
      parallaxInstance.enable();

      return () => parallaxInstance.disable();
    }
  }, [mobileResolution]);

  useEffect(() => {
    function handleResize() {
      console.log('resized to: ', window.innerWidth, 'x', window.innerHeight);

      if (window.innerWidth < 576) {
        setMobileResolution(true);
      } else {
        setMobileResolution(false);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
  });

  return (
    <section
      className={classes.root + ' main-section'}
      style={{ display: props.showMain ? 'none' : 'block' }}
    >
      {!mobileResolution && (
        <div className={classes.scene} ref={sceneEl}>
          <li className={classes.layer + ' layer'} data-depth="0.00">
            <div style={{ backgroundImage: `url(${bgLayer01})` }} />
          </li>
          <li className={classes.layer + ' layer'} data-depth="0.05">
            <div style={{ backgroundImage: `url(${bgLayer02})` }} />
          </li>
          <li className={classes.layer + ' layer'} data-depth="0">
            <div
              className={'fog'}
              style={{
                backgroundImage: `url(${bgLayer03})`,
              }}
            />
          </li>
          <li className={classes.layer + ' layer'} data-depth="0.15">
            <div style={{ backgroundImage: `url(${bgLayer04})` }} />
          </li>
          <li
            className={classes.layer + ' layer beast-layer'}
            data-depth="0.15"
          >
            {beast == 1 && (
              <div
                className={'beast'}
                style={{ backgroundImage: `url(${babo})` }}
              />
            )}
            {beast == 2 && (
              <div
                className={'beast'}
                style={{ backgroundImage: `url(${cruelick})` }}
              />
            )}
            {beast == 3 && (
              <div
                className={'beast'}
                style={{ backgroundImage: `url(${green})` }}
              />
            )}
            {beast == 4 && (
              <div
                className={'beast'}
                style={{ backgroundImage: `url(${pear})` }}
              />
            )}
            {beast == 5 && (
              <div
                className={'beast'}
                style={{ backgroundImage: `url(${shi})` }}
              />
            )}
            {beast == 6 && (
              <div
                className={'beast'}
                style={{ backgroundImage: `url(${wolf})` }}
              />
            )}
          </li>
          <li className={classes.layer + ' layer'} data-depth="0.20">
            <div style={{ backgroundImage: `url(${bgLayer05})` }} />
          </li>
          <li className={classes.layer + ' layer'} data-depth="0">
            <div
              className={'fog-bottom'}
              style={{ backgroundImage: `url(${bgLayer06})` }}
            />
          </li>
          <li className={classes.layer + ' layer'} data-depth="0.35">
            <div style={{ backgroundImage: `url(${bgLayer07})` }} />
          </li>
          <li className={classes.layer + ' layer'} data-depth="0.35">
            <div style={{ backgroundImage: `url(${bgLayer08})` }} />
          </li>
          <li className={classes.layer + ' layer'} data-depth="0.35">
            <div style={{ backgroundImage: `url(${bgLayer08})` }} />
          </li>
        </div>
      )}

      {mobileResolution && (
        <div className={'main-mobile-bg'}>
          <video autoPlay playsInline muted="true" loop id="myVideo">
            <source src={mobileBg} type="video/mp4" />
          </video>
        </div>
      )}

      <Menu />
    </section>
  );
}

export default Main;
