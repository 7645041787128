import React from 'react';
import { createUseStyles } from 'react-jss';

import discordIcon from '../../assets/img/icons/icon_discord_96x96.png';
import discordIconSel from '../../assets/img/icons/icon_discord_sel_122x122.png';

import facebookIcon from '../../assets/img/icons/icon_facebook_96x96.png';
import facebookIconSel from '../../assets/img/icons/icon_facebook_sel_122x122.png';

import steamIcon from '../../assets/img/icons/icon_steam_96x96.png';
import steamIconSel from '../../assets/img/icons/icon_steam_sel_122x122.png';

import twitterIcon from '../../assets/img/icons/icon_twitter_96x96.png';
import twitterIconSel from '../../assets/img/icons/icon_twitter_sel_122x122.png';

import youtubeIcon from '../../assets/img/icons/icon_youtube_96x96.png';
import youtubeIconSel from '../../assets/img/icons/icon_youtube_sel_122x122.png';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background:
      'radial-gradient(50% 8647.03% at 50% 50%, rgba(0, 0, 0, 0.5) 37.97%, rgba(0, 0, 0, 0) 100%)',
    backdropFilter: 'blur(15px)',
    zIndex: 1000,
    '&.hide': {
      display: 'none',
    },
    '& .container': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',

      width: '80%',
      maxWidth: '960px',
    },
    '& h1': {
      fontSize: '3rem',
      fontWeight: '700',
      textAlign: 'center',
      textTransform: 'uppercase',
      color: '#CC985E',
    },
  },
  header: {},
  paragraph: {
    color: '#CC985E',
    textAlign: 'center',
    paddingBottom: '0.5rem',
    '& a': {
      textDecoration: 'underline',
      color: 'inherit',
    },
  },
  horizontalLine: {
    display: 'block',
    width: '100%',
    objectFit: 'contain',
    margin: '0 auto',
    paddingBottom: '0.5rem',
  },
  slider: {
    '& .slide-arrow': {},
    '& img.slick-arrow': {
      height: 'auto',
      width: '6rem',
      '&.slick-prev': {
        left: '-6rem',
      },
      '&.slick-next': {
        right: '-6rem',
      },
    },
    '& .single-slide': {
      '& .image-wrapper': {
        '& img': {
          width: '100%',
        },
      },
      '& .content-wrapper': {
        '& h2': {
          color: '#CC985E',
          fontSize: '2rem',
          fontWeight: '700',
          textAlign: 'center',
          textTransform: 'uppercase',
        },
        '& p': {
          color: '#CC985E',
          textAlign: 'center',
        },
      },
    },
  },
  iconCol: {
    transform: 'rotate(4deg)',
    marginBottom: '1rem',
    '&:nth-of-type(2n+1)': {
      transform: 'rotate(-4deg)',
    },
  },
  socialIcon: {
    display: 'flex',
    justifyContent: 'center',
    height: 'auto',
    width: 'auto',
    margin: 'auto',
    '& .icon': {
      display: 'block',
      height: '122px',
      width: '122px',
      margin: 'auto 0',
      backgroundSize: 'auto',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '1rem',
      backgroundSize: 'contain',
      '&.discord': {
        backgroundImage: `url(${discordIcon})`,
        '&:hover': {
          backgroundImage: `url(${discordIconSel})`,
        },
      },
      '&.facebook': {
        backgroundImage: `url(${facebookIcon})`,
        '&:hover': {
          backgroundImage: `url(${facebookIconSel})`,
        },
      },
      '&.steam': {
        backgroundImage: `url(${steamIcon})`,
        '&:hover': {
          backgroundImage: `url(${steamIconSel})`,
        },
      },
      '&.twitter': {
        backgroundImage: `url(${twitterIcon})`,
        '&:hover': {
          backgroundImage: `url(${twitterIconSel})`,
        },
      },
      '&.youtube': {
        backgroundImage: `url(${youtubeIcon})`,
        '&:hover': {
          backgroundImage: `url(${youtubeIconSel})`,
        },
      },
    },
  },
});

export default useStyles;
