import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    maxHeight: '100vh',
    height: '100vh',
    maxWidth: '100vw',
    overflow: 'hidden',
    position: 'absolute',
    top: '0rem',
    left: '0rem',
    right: '0rem',
    bottom: '0rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignContent: 'center',
    zIndex: 100000000,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',

    '&.swiped': {
      animation: 'swipeUp 1s normal forwards',
    },
    '& .container': {
      padding: '2rem 0',
      background:
        'radial-gradient(50% 8647.03% at 50% 50%, rgba(0, 0, 0, 0.5) 37.97%, rgba(0, 0, 0, 0) 100%)',

      '& .col': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        margin: '0 auto',

        '& h5': {
          fontSize: '1.375rem',
          fontWeight: '700',
          color: '#38BF92',
          textTransform: 'uppercase',
        },

        '& a': {
          animation: 'swipeDownInfinite 1s infinite',
          '& img': {
            display: 'block',
            width: 'auto',
            height: '5rem',
          },
        },
      },
    },
  },
});

export default useStyles;
