import React, { useState, useEffect } from 'react';
import useStyles from './PlayNow.style';
import horizontalLine from '../../assets/img/icons/line_horizontal.png';
import AppStore from '../../assets/img/badges/app1.png';

import GooglePlay from '../../assets/img/badges/play1.png';
import Steam from '../../assets/img/badges/steam1.png';
import Nintendo from '../../assets/img/badges/nintendo2.png';

function PlayNow(props) {
  const classes = useStyles();

  return (
    <section
      className={classes.root + ' community-section mobile-blur'}
      style={{
        paddingBottom: document.getElementById('main-header').offsetHeight,
      }}
    >
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col col-xl-12'}>
            <h1 className={classes.header}>PLAY NOW</h1>
            <img className={classes.horizontalLine} src={horizontalLine} />
          </div>
        </div>

        <div className={'row'}>
          <div
            className={'col col-xl-3 col-md-4 col-12' + ' ' + classes.iconCol}
          >
            <a
              className={classes.socialIcon}
              href={'https://apps.apple.com/pl/app/furryfury'}
              target={'_blank'}
            >
              <img className={'social-icon'} src={AppStore}></img>
            </a>
          </div>

          <div
            className={'col col-xl-3 col-md-4 col-12' + ' ' + classes.iconCol}
          >
            <a
              className={classes.socialIcon}
              href={
                'https://play.google.com/store/apps/details?id=com.CosmicDreams.FurryFury'
              }
              target={'_blank'}
            >
              <img className={'social-icon'} src={GooglePlay}></img>
            </a>
          </div>

          <div
            className={'col col-xl-3 col-md-4 col-12' + ' ' + classes.iconCol}
          >
            <a
              className={classes.socialIcon}
              href={'https://store.steampowered.com/app/906870/FurryFury/'}
              target={'_blank'}
            >
              <img className={'social-icon'} src={Steam}></img>
            </a>
          </div>

          <div
            className={'col col-xl-3 col-md-4 col-12' + ' ' + classes.iconCol}
          >
            <a
              className={classes.socialIcon}
              href={'https://www.nintendo.com/'}
              target={'_blank'}
            >
              <img className={'social-icon'} src={Nintendo}></img>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PlayNow;
