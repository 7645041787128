import React from 'react';
import { createUseStyles } from 'react-jss';
import playBtn from '../../assets/img/icons/play_button.png';
import playBtnSel from '../../assets/img/icons/play_button_sel.png';
import communityBtn from '../../assets/img/icons/menu_button_community_132x132.png';
import communityBtnSel from '../../assets/img/icons/menu_button_community_sel_132x132.png';
import featuresBtn from '../../assets/img/icons/menu_button_features_132x132.png';
import featuresBtnSel from '../../assets/img/icons/menu_button_features_sel_132x132.png';
import mediaBtn from '../../assets/img/icons/menu_button_media_132x132.png';
import mediaBtnSel from '../../assets/img/icons/menu_button_media_sel_132x132.png';
import newsBtn from '../../assets/img/icons/menu_button_news_132x132.png';
import newsBtnSel from '../../assets/img/icons/menu_button_news_sel_132x132.png';
import presskitBtn from '../../assets/img/icons/menu_button_presskit_132x132.png';
import presskitBtnSel from '../../assets/img/icons/menu_button_presskit_sel_132x132.png';
import langPlBtn from '../../assets/img/icons/menu_button_lang_polish_132x132.png';
import langPlBtnSel from '../../assets/img/icons/menu_button_lang_polish_sel_132x132.png';
import langEnBtn from '../../assets/img/icons/menu_button_lang_english_132x132.png';
import langEnBtnSel from '../../assets/img/icons/menu_button_lang_english_sel_132x132.png';

const useStyles = createUseStyles({
  root: {
    padding: '1rem',
    position: 'absolute',
    left: '0px',
    right: '0px',
    bottom: '0px',
    top: 'auto',
    background:
      'radial-gradient(50% 8647.03% at 50% 50%, rgba(0, 0, 0, 0.5) 37.97%, rgba(0, 0, 0, 0) 100%)',
    zIndex: 10000,
  },
  button: {
    display: 'inline-block',
    fontWeight: '700',
    lineHeight: '1',
    border: 'none',

    '&.iconBtn': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      height: '100%',
      textDecoration: 'none',
      paddingBottom: '1rem',

      '&:focus, &:active': {
        outline: 0,
        border: 'none',
        MozOutlineStyle: 'none',
      },
      '& .btnIcon': {
        width: '7rem',
        height: '6rem',
        margin: '0 auto',
        display: 'block',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginBottom: '1rem',
        overflow: 'auto',

        '&:hover': {
          cursor: 'pointer',
        },
        '&.language': {
          backgroundImage: `url(${langEnBtn})`,
          '&:hover': {
            backgroundImage: `url(${langEnBtnSel})`,
          },
        },
        '&.news': {
          backgroundImage: `url(${newsBtn})`,
          '&:hover': {
            backgroundImage: `url(${newsBtnSel})`,
          },
        },
        '&.community': {
          backgroundImage: `url(${communityBtn})`,
          '&:hover': {
            backgroundImage: `url(${communityBtnSel})`,
          },
        },
        '&.presskit': {
          backgroundImage: `url(${presskitBtn})`,
          '&:hover': {
            backgroundImage: `url(${presskitBtnSel})`,
          },
        },
        '&.features': {
          backgroundImage: `url(${featuresBtn})`,
          '&:hover': {
            backgroundImage: `url(${featuresBtnSel})`,
          },
        },
        '&.media': {
          backgroundImage: `url(${mediaBtn})`,
          '&:hover': {
            backgroundImage: `url(${mediaBtnSel})`,
          },
        },
      },
      '& .btnLabel': {
        display: 'inline-block',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#CC985E',
        fontSize: '1.5rem',
      },
    },
  },
  buttonPlay: {
    width: '22.1875rem',
    height: '13.75rem',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    paddingTop: '4rem',
    paddingBottom: '1rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
    backgroundImage: `url(${playBtn})`,
    position: 'absolute',
    right: '0rem',
    bottom: '-1rem',
    '&:focus, &:active': {
      outline: 0,
      border: 'none',
      MozOutlineStyle: 'none',
    },

    '&:hover': {
      backgroundImage: `url(${playBtnSel})`,
      cursor: 'pointer',
    },
    '& span': {
      WebkitTextStroke: '#5A1D04 1.5px',
    },
    '& .top': {
      textTransform: 'uppercase',
      fontWeight: '900',
      display: 'block',
      fontSize: '2.75rem',
      textAlign: 'center',
      color: '#ffffff',
    },
    '& .bottom': {
      display: 'block',
      fontSize: '1.5rem',
      textAlign: 'center',
      color: '#ffffff',
      textTransform: 'uppercase',
      fontWeight: '900',
    },
  },
  column: {
    '&.button-column': {
      flexGrow: 0,
      paddingLeft: '22.1875rem',
    },
  },
});

export default useStyles;
