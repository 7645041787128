import React from 'react';
import { createUseStyles } from 'react-jss';

import polishIcon from '../../assets/img/icons/menu_button_lang_polish_132x132.png';
import polishIconSel from '../../assets/img/icons/menu_button_lang_polish_sel_132x132.png';

import englishIcon from '../../assets/img/icons/menu_button_lang_english_132x132.png';
import englishIconSel from '../../assets/img/icons/menu_button_lang_english_sel_132x132.png';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background:
      'radial-gradient(50% 8647.03% at 50% 50%, rgba(0, 0, 0, 0.5) 37.97%, rgba(0, 0, 0, 0) 100%)',
    backdropFilter: 'blur(15px)',
    zIndex: 1000,
    '&.hide': {
      display: 'none',
    },
    '& .container': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '80%',
      maxHeight: '680px',
      width: '80%',
      maxWidth: '960px',
    },
    '& h1': {
      fontSize: '3rem',
      fontWeight: '700',
      textAlign: 'center',
      textTransform: 'uppercase',
      color: '#CC985E',
    },
  },
  header: {},
  paragraph: {
    color: '#CC985E',
    textAlign: 'center',
    paddingBottom: '0.5rem',
    '& a': {
      textDecoration: 'underline',
      color: 'inherit',
    },
  },
  horizontalLine: {
    display: 'block',
    width: '100%',
    objectFit: 'contain',
    margin: '0 auto',
    paddingBottom: '0.5rem',
  },
  slider: {
    '& .slide-arrow': {},
    '& img.slick-arrow': {
      height: 'auto',
      width: '6rem',
      '&.slick-prev': {
        left: '-6rem',
      },
      '&.slick-next': {
        right: '-6rem',
      },
    },
    '& .single-slide': {
      '& .image-wrapper': {
        '& img': {
          width: '100%',
        },
      },
      '& .content-wrapper': {
        '& h2': {
          color: '#CC985E',
          fontSize: '2rem',
          fontWeight: '700',
          textAlign: 'center',
          textTransform: 'uppercase',
        },
        '& p': {
          color: '#CC985E',
          textAlign: 'center',
        },
      },
    },
  },
  langSwitcher: {
    '& .col': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
    },
  },
  langIcon: {
    display: 'block',
    height: 'auto',
    width: 'auto',
    margin: 'auto',
    '& .icon': {
      display: 'block',
      height: '132px',
      width: '132px',
      margin: 'auto 0',
      backgroundSize: 'auto',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '1rem',
      '&.polish': {
        backgroundImage: `url(${polishIcon})`,
        '&:hover': {
          backgroundImage: `url(${polishIconSel})`,
        },
      },
      '&.english': {
        backgroundImage: `url(${englishIcon})`,
        '&:hover': {
          backgroundImage: `url(${englishIconSel})`,
        },
      },
    },
  },
});

export default useStyles;
