import React, { useState } from 'react';
import Main from '../../components/Main/Main';
import Splash from '../../components/Splash/Splash';

function Home() {
  const [scrollable, setScrollable] = useState(true);
  const [showMain, setShowMain] = useState(true);

  const handleScroll = () => {
    setScrollable(false);
  };
  const handleScrollInit = () => {
    setShowMain(false);
  };

  return (
    <div className="Home">
      {scrollable && (
        <Splash
          scrollHandlerInit={handleScrollInit}
          scrollHandler={handleScroll}
        />
      )}
      <Main showMain={showMain} />
    </div>
  );
}

export default Home;
