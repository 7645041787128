import React, { useState, useEffect } from 'react';
import useStyles from './Splash.style';
import getAcf from '../../api/getAcf';

import arrow05 from '../../assets/img/icons/arrow5.png';

function Splash(props) {
  const classes = useStyles();

  const [scrollable, setScrollable] = useState(true);

  const [acf, setAcf] = useState([]);

  useEffect(() => {
    getAcf('splash-screen').then((result) => setAcf(result[0].acf));
  }, []);

  const handleScroll = () => {
    if (scrollable) {
      setScrollable(false);
      console.log(scrollable);
      console.log('SCROLL');
      let splashScreen = document.getElementById('splash-screen');
      if (splashScreen != null) {
        splashScreen.classList.add('swiped');
        setScrollable(false);
        props.scrollHandlerInit();

        setTimeout(() => {
          console.log('Scrolled');
          props.scrollHandler();
        }, 1000);
      }
    }
  };

  return (
    <section
      id={'splash-screen'}
      className={classes.root}
      style={{
        backgroundImage: `url(${acf.zdjecie_w_tle}})`,
      }}
      onWheel={() => handleScroll()}
      onTouchMove={() => handleScroll()}
    >
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col col-xl-12'}>
            <h5
              className={classes.header}
              dangerouslySetInnerHTML={{ __html: acf.tekst }}
            ></h5>
            <a onClick={() => handleScroll()} href={'#'}>
              <img className={classes.arrow} src={arrow05} />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Splash;
