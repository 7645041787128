import React from 'react';
import {createUseStyles} from 'react-jss'

const useStyles = createUseStyles({
    root: {
        background: "#1C1C1C",
        color: "#cccccc",

        "& h1": {
            fontSize: "2rem",
            paddingBottom: "1rem",
        },
        "& h5": {
            fontSize: "1.25rem",
            paddingBottom: "1rem",
            textTransform: "uppercase",
        },
        "& p": {
            paddingBottom: "1rem",

            "& a": {
                color: "#00f7ca",
            }
        },

        "& .text-component": {
            marginBottom: "1rem",
            "&.highlighted": {
                padding: "1rem",
                background: "#282828"
            }
        },

        "& .image-component": {
            "& img": {
                display: "block",
                width: "100%",
            }
        }
    }
});

export default useStyles;
