import React, { useState, useEffect } from 'react';
import useStyles from './News.style';
import Slider from 'react-slick';
import horizontalLine from '../../assets/img/icons/line_horizontal.png';
import getPosts from '../../api/getPosts';

import xcross from '../../assets/img/icons/xrosss.png';
// import horizontalLine from '../../assets/img/icons/line_horizontal.png';

function News(props) {
  const classes = useStyles();

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    getPosts.then((result) => setPosts(result));
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    touchMove: false,
    nextArrow: <div></div>,
    prevArrow: <div></div>,
  };

  return (
    <section
      className={classes.root + '  news-section mobile-blur'}
      // style={{
      //   paddingBottom: document.getElementById('main-header').offsetHeight,
      // }}
    >
      <div
        className={'container'}
        style={{
          paddingBottom: document.getElementById('main-header').offsetHeight,
        }}
      >
        <div className={'row'}>
          <div className={'col col-xl-12'}>
            <h1 className={classes.header}>
              News
              <span className={'close-button'} onClick={props.handleClick} />
            </h1>
            <img className={classes.horizontalLine} src={horizontalLine} />
          </div>
        </div>
        <div className={'row'}>
          <div className={'col col-xl-12 slider-col'}>
            <div>
              <Slider className={classes.slider} {...settings}>
                {posts.map(
                  (post) =>
                    (post = post['post_type'] == 'post' && (
                      <div className={'single-slide'}>
                        <div className={'image-wrapper'}>
                          <img src={post['thumbnail_url']} />
                        </div>
                        <div className={'header-wrapper'}>
                          <h4>{post['post_title']}</h4>
                          <p>{post['date_formatted']}</p>
                        </div>

                        <div
                          className={`content-wrapper ${
                            post['thumbnail_url'] ? ' with-image' : ''
                          }`}
                        >
                          <p
                            dangerouslySetInnerHTML={{
                              __html: post['post_content'],
                            }}
                          />
                        </div>
                      </div>
                    ))
                )}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default News;
