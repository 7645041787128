import useStyles from './Community.style';
import horizontalLine from '../../assets/img/icons/line_horizontal.png';

function Community(props) {
  const classes = useStyles();

  return (
    <section
      className={classes.root + ' community-section mobile-blur'}
      style={{
        paddingBottom: document.getElementById('main-header').offsetHeight,
      }}
    >
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col col-xl-12'}>
            <h1 className={classes.header}>
              Community{' '}
              <span className={'close-button'} onClick={props.handleClick} />
            </h1>
            <img className={classes.horizontalLine} src={horizontalLine} />
          </div>
        </div>
        <div className={'row'}>
          <div className={'col col-xl-12'}>
            <p className={classes.paragraph + ' ' + 'centered'}>
              Join the community on your favourite platform
            </p>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col col-xl-1 col-md-12 col-12'}></div>
          <div
            className={'col col-4 col-xl-2 col-md-4' + ' ' + classes.iconCol}
          >
            <a
              className={classes.socialIcon}
              href={'https://twitter.com/FurryFuryGame'}
              target={'_blank'}
            >
              <span className={'icon' + ' ' + 'twitter'}></span>
            </a>
          </div>
          <div
            className={'col col-4 col-xl-2 col-md-4' + ' ' + classes.iconCol}
          >
            <a
              className={classes.socialIcon}
              href={'https://discord.com/invite/KnfHzjt'}
              target={'_blank'}
            >
              <span className={'icon' + ' ' + 'discord'}></span>
            </a>
          </div>
          <div
            className={'col col-4 col-xl-2 col-md-4' + ' ' + classes.iconCol}
          >
            <a
              className={classes.socialIcon}
              href={'https://store.steampowered.com/app/906870/FurryFury/'}
              target={'_blank'}
            >
              <span className={'icon' + ' ' + 'steam'}></span>
            </a>
          </div>
          <div
            className={'col col-6 col-xl-2 col-md-6' + ' ' + classes.iconCol}
          >
            <a
              className={classes.socialIcon}
              href={'https://www.facebook.com/FurryFuryGame'}
              target={'_blank'}
            >
              <span className={'icon' + ' ' + 'facebook'}></span>
            </a>
          </div>
          <div
            className={'col col-6 col-xl-2 col-md-6' + ' ' + classes.iconCol}
          >
            <a
              className={classes.socialIcon}
              href={'https://www.youtube.com/channel/UCgx59smNRct5f-Bt-C9qGwQ'}
              target={'_blank'}
            >
              <span className={'icon' + ' ' + 'youtube'}></span>
            </a>
          </div>
          <div className={'col col-xl-1 col-md-12 col-12'}></div>
        </div>
        <div className={'row'}>
          <div className={'col col-xl-12'}>
            <p className={classes.paragraph + ' ' + 'centered'}>
              or write us an email (apparently it was a blast in nineties) at:{' '}
              <a href={'mailto:contact@furyfury.com'}>contact@furyfury.com</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Community;
