import React, { useState, useEffect } from 'react';
import useStyles from './Media.style';
import Slider from 'react-slick';
import horizontalLine from '../../assets/img/icons/line_horizontal.png';

import getAcf from '../../api/getAcf';

function Media(props) {
  const classes = useStyles();
  const [showGallery, setShowGallery] = useState(false);
  const [galleryUrl, setGalleryUrl] = useState('');
  const [acf, setAcf] = useState(false);

  useEffect(() => {
    getAcf('media').then((result) => setAcf(result[0].acf));
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    nextArrow: <div></div>,
    prevArrow: <div></div>,
  };

  const handleImageClick = (e) => {
    setShowGallery(!showGallery);
    if (e.target.src) {
      setGalleryUrl(e.target.src);
    }
  };

  return (
    <section
      className={classes.root + ' mobile-blur'}
      style={{ zIndex: showGallery ? '10001' : '1000' }}
    >
      {showGallery && (
        <div className={'gallery-overlay'} onClick={handleImageClick}>
          <img src={galleryUrl} />
        </div>
      )}
      <div
        className={'container'}
        style={{
          paddingBottom: document.getElementById('main-header').offsetHeight,
        }}
      >
        <div className={'row'}>
          <div className={'col col-xl-12'}>
            <h1 className={classes.header}>
              Media{' '}
              <span className={'close-button'} onClick={props.handleClick} />
            </h1>
            <img className={classes.horizontalLine} src={horizontalLine} />
          </div>
        </div>
        <div className={'row'}>
          <div className={'col col-xl-12 slider-col media-slider'}>
            <div>
              <Slider className={classes.slider} {...settings}>
                <div className={'single-slide'}>
                  <div className={'video-wrapper'}>
                    {acf && (
                      <iframe
                        width="560"
                        height="315"
                        src={acf.galeria.film_yt}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    )}
                  </div>
                </div>
                <div className={'single-slide'}>
                  <div className={'image-wrapper'}>
                    {acf && (
                      <img
                        src={acf.galeria.obraz_1}
                        onClick={handleImageClick}
                      />
                    )}
                  </div>
                </div>
                <div className={'single-slide'}>
                  <div className={'image-wrapper'}>
                    {acf && (
                      <img
                        src={acf.galeria.obraz_2}
                        onClick={handleImageClick}
                      />
                    )}{' '}
                  </div>
                </div>
                <div className={'single-slide'}>
                  <div className={'image-wrapper'}>
                    {acf && (
                      <img
                        src={acf.galeria.obraz_3}
                        onClick={handleImageClick}
                      />
                    )}{' '}
                  </div>
                </div>
                <div className={'single-slide'}>
                  <div className={'image-wrapper'}>
                    {acf && (
                      <img
                        src={acf.galeria.obraz_4}
                        onClick={handleImageClick}
                      />
                    )}{' '}
                  </div>
                </div>
                <div className={'single-slide'}>
                  <div className={'image-wrapper'}>
                    {acf && (
                      <img
                        src={acf.galeria.obraz_5}
                        onClick={handleImageClick}
                      />
                    )}{' '}
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Media;
