import useStyles from "./PressKit.style";
import pressKit from "../../assets/img/presskit.jpg";

function PressKit() {
    const classes = useStyles();

    return (
        <div className={classes.root + " " + "PressKit"}>
            <section>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col col-xl-12"}>
                            <div className={"component" + " " + "image-component"}>
                                <div className={"component-inner"}>
                                    <img src={pressKit} />
                                </div>
                            </div>
                            <div className={"component" + " " + "text-component"}>
                                <div className={"component-inner"}>
                                    <h1>Press kit</h1>
                                    <h5>FACTSHEET</h5>
                                    <p>Developer<br/>
                                        Demibug Studio<br/>
                                        Based in Warsaw, Poland</p>
                                    <p>Steam Early Access launch date:<br/>
                                        July 11th, 2019</p>
                                    <p>Planned release date:<br/>
                                        3rd quarter of 2020</p>
                                    <p>Website:<br/>
                                        <a href="furryfurygame.com">furryfurygame.com</a></p>
                                    <h5>ABOUT THE STUDIO</h5>
                                    <p>Demibug is a one-person independent game studio founded in 2017 by Marcin Michalski (previously working on such titles as Cyberpunk 2077, The Witcher 3, The Witcher 2, Gwent and Darkwood), based in Warsaw, Poland. FurryFury is the first game of the studio.</p>
                                </div>
                            </div>
                            <div className={"component" + " " + "text-component" + " " + "highlighted"}>
                                <div className={"component-inner"}>
                                    <h5>ABOUT FURRYFURY</h5>

                                    <p>FurryFury is a dynamic beast-rolling physics-based online arena brawl, where the deceivingly cute beasts settle their differences by rolling and smashing against one another in a deadly arenas. Play solo, with a friend (or against him), beat the challenges or battle other players online.</p>

                                    <h5>KEY FEATURES</h5>

                                    <h5>Physics-based gameplay</h5>
                                    <p>Aim, roll, sit back and enjoy watching things bounce and explode. Easy to learn, hard to master.</p>

                                    <h5>Fast paced, simultaneous turns</h5>
                                    <p>Plan together with your teammate to launch devastating combos.</p>

                                    <h5>Beasts evolving over the course of a match</h5>
                                    <p>There is a tactical choice between getting stronger and dealing direct damage (you can still do both at once if you're awesome).</p>

                                    <h5>Become a ghost</h5>
                                    <p>Upon death you turn into a ghost to actively bother your enemies.</p>

                                    <h5>CO-OP</h5>
                                    <p>Every game mode can be played with a friend.</p>

                                    <h5>Fair progression system</h5>
                                    <p>There are many beasts to play as, and each of them has a different set of attributes and skills. But you can also customize their move effect, victory quote, background fx and more. Simply by playing.</p>

                                    <h5>No LAG / delay</h5>
                                    <p>Thanks to the author physics system, all behaviour is deterministic therefore performed locally, and synced only in key moments. Also disconnected (rage-quitted) players are automatically turned into Bots so nothing interrupts the match.</p>
                                </div>
                            </div>
                            <div className={"component" + " " + "text-component"}>
                                <div className={"component-inner"}>
                                    <h1>Media</h1>
                                    <h5>Videos</h5>
                                    <h5>Screenshots, wallpapers and logos:</h5>
                                    <p>download all pictures as .rar file <a href={"%PUBLIC_URL%/furryfury_screenshots_pack.rar"}>here</a></p>
                                </div>
                            </div>
                            <div className={"component" + " " + "text-component" + " " + "highlighted"}>
                                <div className={"component-inner"}>
                                    <h5>Selected articles</h5>
                                    <p>- "With all the possibilities, mechanics and tactics, FurryFury still has a very low entrance threshold and can be played very casually."</p>
                                    <p>- Stuart Brown, <a href="https://www.indiegamemag.com/furryfury-a-f2p-dynamic-turn-based-beast-rolling-online-arena-brawl-on-physics/">Indie Game Magazine</a></p>
                                </div>
                            </div>
                            <div className={"component" + " " + "text-component"}>
                                <div className={"component-inner"}>
                                    <h5>Contact and links</h5>
                                    <p>Email:<br />
                                        <a href="mailto:contact@demibug.com" target={"_blank"}>contact@demibug.com</a></p>
                                    <p>Twitter:<br />
                                        <a href="twitter.com/FurryFuryGame" target={"_blank"}>twitter.com/FurryFuryGame</a></p>
                                    <p>Facebook:<br />
                                        <a href="www.facebook.com/FurryFuryGame" target={"_blank"}>www.facebook.com/FurryFuryGame</a></p>
                                    <p>Steam store:<br />
                                        <a href="https://store.steampowered.com/app/906870/FurryFury/" target={"_blank"}>https://store.steampowered.com/app/906870/FurryFury/</a></p>
                                    <p>Discord:<br />
                                        <a href="https://discord.com/invite/KnfHzjt" target={"_blank"}>https://discord.com/invite/KnfHzjt</a></p>
                                    <p>Youtube channel:<br />
                                        <a href="https://www.youtube.com/channel/UCgx59smNRct5f-Bt-C9qGwQ" target={"_blank"}>https://www.youtube.com/channel/UCgx59smNRct5f-Bt-C9qGwQ</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default PressKit;
