import React, { useState, useEffect } from 'react';
import useStyles from './Menu.style';
import { act } from '@testing-library/react';
import Features from '../Features/Features';
import Community from '../Community/Community';
import Media from '../Media/Media';
import Language from '../Language/Language';
import News from '../News/News';
import PlayNow from '../PlayNow/PlayNow';

// import bgLayer01 from "../../assets/img/layers/layer_01.png";

function Menu(props) {
  const classes = useStyles();

  const [translations, setTranslations] = useState({});

  const [active, setActive] = useState('');

  useEffect(() => {
    document.addEventListener(
      'keydown',
      (event) => {
        if (event.key === 'Escape') {
          handleSwitch('');
        }
      },
      false
    );
  }, []);

  const handleClick = () => {
    console.log('CLICK');
  };

  const handleSwitch = (target) => {
    if (active != target) {
      setActive(target);
    } else {
      setActive('');
    }
  };

  return (
    <>
      <div id={'close-button'} onClick={() => handleSwitch('')}>
        X
      </div>
      <header id={'main-header'} className={classes.root}>
        <div className={'container-fluid'}>
          <div className={'row'}>
            <div className={'col'}>
              <a
                className={classes.button + ' ' + ' iconBtn'}
                onClick={() => handleSwitch('language')}
              >
                <span className={'btnIcon' + ' ' + 'language'}></span>
                <span className={'btnLabel'}>Language</span>
              </a>
            </div>
            <div className={'col'}>
              <a
                className={classes.button + ' ' + ' iconBtn'}
                onClick={() => handleSwitch('news')}
              >
                <span className={'btnIcon' + ' ' + 'news'}></span>
                <span className={'btnLabel'}>News</span>
              </a>
            </div>
            <div className={'col'}>
              <a
                className={classes.button + ' ' + ' iconBtn'}
                onClick={() => handleSwitch('community')}
              >
                <span className={'btnIcon' + ' ' + 'community'}></span>
                <span className={'btnLabel'}>Community</span>
              </a>
            </div>
            <div className={'col'}>
              <a
                className={classes.button + ' ' + ' iconBtn'}
                href={'./presskit'}
                target={'_blank'}
              >
                <span className={'btnIcon' + ' ' + 'presskit'}></span>
                <span className={'btnLabel'}>Press kit</span>
              </a>
            </div>
            <div className={'col'}>
              <a
                className={classes.button + ' ' + ' iconBtn'}
                onClick={() => handleSwitch('features')}
              >
                <span className={'btnIcon' + ' ' + 'features'}></span>
                <span className={'btnLabel'}>Features</span>
              </a>
            </div>
            <div className={'col'}>
              <a
                className={classes.button + ' ' + ' iconBtn'}
                onClick={() => handleSwitch('media')}
              >
                <span className={'btnIcon' + ' ' + 'media'}></span>
                <span className={'btnLabel'}>Media</span>
              </a>
            </div>
            <div className={'col ' + classes.column + ' button-column'}>
              <a
                className={classes.button + ' ' + classes.buttonPlay}
                onClick={() => handleSwitch('playNow')}
              >
                <span className={'top'}>Play Now</span>
                <span className={'bottom'}>For free</span>
              </a>
            </div>
          </div>
        </div>
      </header>

      <header id={'mobile-header'} className={classes.root}>
        <div className={'container-fluid top-menu'}>
          <div className={'row'}>
            <div className={'col'}>
              <a
                className={classes.button + ' ' + ' iconBtn'}
                onClick={() => handleSwitch('language')}
              >
                <span className={'btnIcon' + ' ' + 'language'}></span>
                <span className={'btnLabel'}>Language</span>
              </a>
            </div>
            <div className={'col'}>
              <a
                className={classes.button + ' ' + ' iconBtn'}
                onClick={() => handleSwitch('news')}
              >
                <span className={'btnIcon' + ' ' + 'news'}></span>
                <span className={'btnLabel'}>News</span>
              </a>
            </div>
            <div className={'col'}>
              <a
                className={classes.button + ' ' + ' iconBtn'}
                onClick={() => handleSwitch('community')}
              >
                <span className={'btnIcon' + ' ' + 'community'}></span>
                <span className={'btnLabel'}>Community</span>
              </a>
            </div>
            <div className={'col'}>
              <a
                className={classes.button + ' ' + ' iconBtn'}
                href={'./presskit'}
                target={'_blank'}
              >
                <span className={'btnIcon' + ' ' + 'presskit'}></span>
                <span className={'btnLabel'}>Press kit</span>
              </a>
            </div>
          </div>
        </div>

        <div className={'container-fluid bottom-menu'}>
          <div className={'row'}>
            <div className={'col'}>
              <a
                className={classes.button + ' ' + ' iconBtn'}
                onClick={() => handleSwitch('features')}
              >
                <span className={'btnIcon' + ' ' + 'features'}></span>
                <span className={'btnLabel'}>Features</span>
              </a>
            </div>

            <div className={'col ' + classes.column + ' button-column'}>
              <a
                className={classes.button + ' ' + classes.buttonPlay}
                onClick={() => handleSwitch('playNow')}
              >
                <span className={'top'}>Play Now</span>
                <span className={'bottom'}>For free</span>
              </a>
            </div>

            <div className={'col'}>
              <a
                className={classes.button + ' ' + ' iconBtn'}
                onClick={() => handleSwitch('media')}
              >
                <span className={'btnIcon' + ' ' + 'media'}></span>
                <span className={'btnLabel'}>Media</span>
              </a>
            </div>
          </div>
        </div>
      </header>

      {active == 'features' && (
        <Features handleClick={() => handleSwitch('')} />
      )}
      {active == 'news' && <News handleClick={() => handleSwitch('')} />}
      {active == 'community' && (
        <Community handleClick={() => handleSwitch('')} />
      )}
      {active == 'language' && (
        <Language handleClick={() => handleSwitch('')} />
      )}
      {active == 'media' && <Media handleClick={() => handleSwitch('')} />}
      {active == 'playNow' && <PlayNow />}
    </>
  );
}

export default Menu;
