import React, { useState, useEffect } from 'react';
import useStyles from './Features.style';
import Slider from 'react-slick';
import horizontalLine from '../../assets/img/icons/line_horizontal.png';
// import featuresArt from '../../assets/img/temp_art_features_920x370.png';
// import nextArrow from '../../assets/img/icons/arrow3.png';
// import prevArrow from '../../assets/img/icons/arrow1.png';

import f1 from '../../assets/img/features/f1.png';
import f2 from '../../assets/img/features/f2.png';
import f3 from '../../assets/img/features/f3.jpg';
import f4 from '../../assets/img/features/f4.png';

// import ImageComponent from '../ImageComponent/ImageCompontent';

import getAcf from '../../api/getAcf';

function Features(props) {
  const classes = useStyles();
  const [showGallery, setShowGallery] = useState(false);
  const [galleryUrl, setGalleryUrl] = useState('');
  const [acf, setAcf] = useState(false);

  useEffect(() => {
    getAcf('features').then((result) => setAcf(result[0].acf));
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    nextArrow: <div></div>,
    prevArrow: <div></div>,
  };

  const handleImageClick = (e) => {
    setShowGallery(!showGallery);
    if (e.target.src) {
      setGalleryUrl(e.target.src);
    }
    console.log(e.target.src);
  };

  return (
    <section
      className={classes.root + ' features-section mobile-blur'}
      style={{ zIndex: showGallery ? '10001' : '1000' }}
    >
      {showGallery && (
        <div className={'gallery-overlay'} onClick={handleImageClick}>
          <img src={galleryUrl} />
        </div>
      )}

      <div
        className={'container'}
        style={{
          paddingBottom: document.getElementById('main-header').offsetHeight,
        }}
      >
        <div className={'row'}>
          <div className={'col col-xl-12'}>
            <h1 className={classes.header}>
              Features{' '}
              <span className={'close-button'} onClick={props.handleClick} />
            </h1>
            <img className={classes.horizontalLine} src={horizontalLine} />
          </div>
        </div>
        <div className={'row'}>
          <div className={'col col-xl-12 slider-col'}>
            <div>
              {acf && (
                <Slider className={classes.slider} {...settings}>
                  <div className={'single-slide'}>
                    <div className={'image-wrapper'}>
                      <img
                        src={acf.galeria.slajd_1.obraz}
                        onClick={handleImageClick}
                      />
                    </div>
                    <div className={'content-wrapper'}>
                      <h2
                        dangerouslySetInnerHTML={{
                          __html: acf.galeria.slajd_1.tytul,
                        }}
                      ></h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: acf.galeria.slajd_1.tresc,
                        }}
                      ></p>
                    </div>
                  </div>
                  <div className={'single-slide'}>
                    <div className={'image-wrapper'}>
                      <img
                        src={acf.galeria.slajd_2.obraz}
                        onClick={handleImageClick}
                      />
                    </div>
                    <div className={'content-wrapper'}>
                      <h2
                        dangerouslySetInnerHTML={{
                          __html: acf.galeria.slajd_2.tytul,
                        }}
                      ></h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: acf.galeria.slajd_2.tresc,
                        }}
                      ></p>
                    </div>
                  </div>
                  <div className={'single-slide'}>
                    <div className={'image-wrapper'}>
                      <img
                        src={acf.galeria.slajd_3.obraz}
                        onClick={handleImageClick}
                      />
                    </div>
                    <div className={'content-wrapper'}>
                      <h2
                        dangerouslySetInnerHTML={{
                          __html: acf.galeria.slajd_3.tytul,
                        }}
                      ></h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: acf.galeria.slajd_3.tresc,
                        }}
                      ></p>
                    </div>
                  </div>
                  <div className={'single-slide'}>
                    <div className={'image-wrapper'}>
                      <img
                        src={acf.galeria.slajd_4.obraz}
                        onClick={handleImageClick}
                      />
                    </div>
                    <div className={'content-wrapper'}>
                      <h2
                        dangerouslySetInnerHTML={{
                          __html: acf.galeria.slajd_4.tytul,
                        }}
                      ></h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: acf.galeria.slajd_4.tresc,
                        }}
                      ></p>
                    </div>
                  </div>
                </Slider>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
