import React from 'react';
import { createUseStyles } from 'react-jss';

import discordIcon from '../../assets/img/icons/icon_discord_96x96.png';
import discordIconSel from '../../assets/img/icons/icon_discord_sel_122x122.png';

import facebookIcon from '../../assets/img/icons/icon_facebook_96x96.png';
import facebookIconSel from '../../assets/img/icons/icon_facebook_sel_122x122.png';

import steamIcon from '../../assets/img/icons/icon_steam_96x96.png';
import steamIconSel from '../../assets/img/icons/icon_steam_sel_122x122.png';

import twitterIcon from '../../assets/img/icons/icon_twitter_96x96.png';
import twitterIconSel from '../../assets/img/icons/icon_twitter_sel_122x122.png';

import youtubeIcon from '../../assets/img/icons/icon_youtube_96x96.png';
import youtubeIconSel from '../../assets/img/icons/icon_youtube_sel_122x122.png';

import AppStore from '../../assets/img/badges/appstore.png';
import GooglePlay from '../../assets/img/badges/google-play.png';
import Steam from '../../assets/img/badges/Steam_Logo.png';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background:
      'radial-gradient(50% 8647.03% at 50% 50%, rgba(0, 0, 0, 0.5) 37.97%, rgba(0, 0, 0, 0) 100%)',
    backdropFilter: 'blur(15px)',
    zIndex: 1000,
    '&.hide': {
      display: 'none',
    },
    '& .container': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',

      width: '80%',
      maxWidth: '960px',
    },
    '& h1': {
      fontSize: '3rem',
      fontWeight: '700',
      textAlign: 'center',
      textTransform: 'uppercase',
      color: '#CC985E',
    },
  },

  paragraph: {
    color: '#CC985E',
    textAlign: 'center',
    paddingBottom: '0.5rem',
    '& a': {
      textDecoration: 'underline',
      color: 'inherit',
    },
  },
  horizontalLine: {
    display: 'block',
    width: '100%',
    objectFit: 'contain',
    margin: '0 auto',
    paddingBottom: '2.5rem',
  },

  iconCol: {
    transform: 'rotate(4deg)',
    marginBottom: '1rem',
    '&:nth-of-type(2n+1)': {
      transform: 'rotate(-4deg)',
    },
  },
  socialIcon: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    margin: 'auto',

    '&:focus': {
      outline: 'none',
    },
    '& .social-icon': {
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
      borderRadius: '20px',
      maxWidth: '200px',
      border: '2px solid #A6A6A6',
      margin: '10px 0',
      '&:hover': {
        boxShadow: '0 0 2rem white',
      },
    },
  },
});

export default useStyles;
