import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    height: '100vh',
    minHeight: '100vh',
    maxWidth: '100vw',
    overflow: 'hidden',
  },
  scene: {
    maxHeight: '100vh',
    overflow: 'hidden',
  },
  layer: {
    position: 'absolute',
    display: 'block',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    minHeight: '100vh',
    '& img': {
      position: 'relative',
      marginTop: 'auto',
      marginBottom: 'auto',
      display: 'block',
      width: 'auto',
    },
    '& div': {
      display: 'block',
      position: 'absolute',
      top: '-50px',
      left: '-50px',
      right: '-50px',
      bottom: '-50px',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      backgroundPositionY: 'bottom',

      '&.fog': {
        backgroundRepeat: 'repeat',
        animation: '48s linear infinite slideRight',
        backgroundSize: '100vw 100%',
      },
      '&.fog-bottom': {
        backgroundRepeat: 'repeat',
        animation: '40s linear infinite slideRight',
        backgroundSize: '100vw 100%',
      },
    },
  },
  layerFullscreen: {
    '& div': {
      backgroundSize: 'cover !important',
    },
  },
});

export default useStyles;
