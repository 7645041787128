import React, { Suspense } from 'react';
import './App.scss';
import { Route, Switch } from 'react-router-dom';
import Home from './views/Home/Home';
import PressKit from './views/PressKit/PressKit';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/presskit" component={PressKit} />
        <Route path="*" component={Home} />
      </Switch>
    </div>
  );
}

export default App;
